
export const isCompanyApp = true;

export const appSettings = {"companyId":"13117","companyName":"Prestige Oil Inc","companyHash":"42t","companyLogo":"assets/icons/company-logo.svg","isPropane":true,"subMenuLogo":"assets/icons/sub-menu-logo.svg","cookieDomain":".instient.com"};

export const domain = {"dev":"https://prestigeoil.instient.com/","uat":"","main":""};

export const appStaticText = {"homeButtoTitle":"CHECK PRICE","oilSelectButtoTitle":"SEARCH","companyNumber":""};

export const appHome = {"title":"Offering Low Cost, Quality Oil in NY","commitment":""};

export const appContactUs = {"pageTitle":"Contact","description":"Prestige Oil LLC prides itself on the prompt delivery of heating oil to all customers in Brewster, NY and surrounding towns. Once you place an order for our quality oil, either by contacting a Prestige representative at (914) 483-6860 or by ordering online, we will promptly deliver it within 48 hours or less, excluding weekends. All orders must be paid for either in advance (by credit card), or at the time of delivery. We currently accept MasterCard and Visa. Prestige can also service your heating and cooling equipment for an additional fee, if needed.","phone":9144836860,"phoneFormatted":"(914) 483-6860","mail":"","sms":9144836860,"smsFormatted":"(914) 483-6860","officeHours":{"title":"Office Hours","weekDays":[{"day":"Monday","timing":"7:30am - 4:00pm"},{"day":"Tuesday","timing":"7:30am - 4:00pm"},{"day":"Wednesday","timing":"7:30am - 4:00pm"},{"day":"Thursday","timing":"7:30am - 4:00pm"},{"day":"Friday","timing":"7:30am - 4:00pm"},{"day":"Saturday","timing":"Closed"},{"day":"Sunday","timing":"Closed"}]},"headQuarters":[{"title":"Headquarters","address":["273 Starr Ridge Rd","Brewster, NY 10509"]}],"maps":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.989078872162!2d-73.60219992446676!3d41.37432199680633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89dd532ec4f4b77f%3A0xa2a5ebc3e6eec1d1!2s273%20Starr%20Ridge%20Rd%2C%20Brewster%2C%20NY%2010509%2C%20USA!5e0!3m2!1sen!2sin!4v1722607442486!5m2!1sen!2sin","socialPlatform":{"title":"","platform":[{"name":"facebook","link":""},{"name":"instagram","link":""},{"name":"linkedin","link":""}]}};

export const appAboutUs = {"pageTitle":"About","paragraph":"<p>Are you shopping for low-cost heating oil delivery for your home or business without the long-term contracts and fees. Prestige Oil has you covered.</p><br><p>We’ve been serving our will-call heating oil customers, providing reliable delivery in the greater Brewster area for over 20 years.</p><br><p>Our customer care team is standing by to take your call at <a class=\"contact-link\" href=\"tel:914-483-6860\">914-483-6860</a>. You can also text us at <a class=\"contact-link\" href=\"sms:914-483-6860\">914-483-6860.</a></p>","teamImageHeader":"The Prestige Oil Team","teamImage":{"title":"","alt":"Photo of the staff and fuel oil delivery drivers at Prestige Oil.","url":"assets/images/team-placeholder.svg"},"teamImageDescription":"Trust our family to keep your family warm all winter long."};

export const appFAQ = {"pageTitle":"FAQs","faqs":[{"question":"What type of credit card can I use to purchase oil?","answer":"<p>We accept Visa, Mastercard and Discover.</p>"},{"question":"How much oil should I order?","answer":"<p>95% of homes have 275 gallons oil tanks, and if you have around 1/4th tank, we recommend ordering 150 gallons to take advantage of bulk discounts, which typically kick in at 150 gallons.</p>"},{"question":"What is a “cash/check deal” and why is it cheaper than a regular credit card deal?","answer":"<p>Many oil companies offer a discount for cash or check payment because this avoids credit card fees. If you grab a cash/check deal, the provider contacts you prior to delivery to arrange payment.</p>"}]};

export const appPrivacyPolicy = {"pageTitle":"Privacy Policy","content":"<p>Privacy Policy Text Here</p>"};

export const appTermsAndConditions = {"pageTitle":"Terms & Conditions","content":"<p>Terms & Conditions Text Here</p>"};

export const appFooter = {"address":["273 Starr Ridge Rd","Brewster, NY 10509",""],"phone":9144836860,"phoneFormatted":"(914) 483-6860","mail":"","sms":9144836860,"smsFormatted":"(914) 483-6860","contactMessage":"Contact our Customer Care team","footerMessage":" ©2025 Prestige Oil. All Rights Reserved. <a href=\"/privacy-policy\">Privacy Policy</a>. <a href=\"/terms-conditions\">Terms & Conditions.</a>"};

export const appHowItWorks = {"companyInfo1":"Prestige Oil has provided the greater Brewster community with affordable, reliable will-call heating oil delivery for two decades and counting.","companyInfo2":"If you find a competitor delivering heating oil at a lower price than us, we’ll beat that price by 2¢, <span style=\"color: #CF2028;\" class=\"hf-dark-font\">GUARANTEED</span>!","howItWorks1":{"title":"Check today’s price","info":"Enter your ZIP code and order quantity for a real-time quote."},"howItWorks2":{"title":"Place your order","info":"Placing an order on our app takes two minutes or less."},"howItWorks3":{"title":"Receive your delivery","info":"We’ll deliver on or before the promised delivery date, and you don’t need to be home for delivery."},"dealAlerts":{"title":"Deal Alerts","subscribeDetails":"Subscribe to deal alerts and we'll send promotion announcements directly to your inbox."}};
